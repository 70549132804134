import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Mapcontact,
  NotFound,
  SEO,
  Spinner,
  TitleSection,
} from "../../components";
import { FaArrowRight } from "react-icons/fa";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "../News/styles.scss";
import url from "../../url.json";

function PartnorsDetails({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    fetch(url.url + `/about/partners/show/${searchParams.get("slug")}`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, [selectlan]);
  return (
    <>
      <SEO title={data?.name} name={data?.name} />
      <section
        className="title_page detail__w"
        style={{ backgroundImage: `url(images/tender_bc.jpg)` }}
      >
        <div className="container">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <h1> {data?.name}</h1>
            <img style={{ background: "white" }} src={data?.image} alt="" />
          </h1>
        </div>
      </section>
      <section className="projects__detail__page__context">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/projects">{t("projects")}</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {data?.name}
            </li>
          </ol>
          {data ? (
            <div className="det">
              <h3>{t("General_information")}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
                className="card-text"
              />
              <h3>{t("website")}</h3>

              <a href={data?.website ? data?.website : "#"} target="_blank">
                <p>
                  {t("website")} <FaArrowRight color="#0f4c82" fill="#0f4c82" />
                </p>
              </a>

              <h3>{t("projects")}</h3>
              {data?.projects?.map((res) => (
                <p
                  style={{
                    cursor: "pointer",
                    color: "#0f4c82",
                    fontWeight: 500,
                  }}
                  key={res?.id}
                  onClick={() => navigate(`/project_detail?slug=${res?.slug}`)}
                >
                  {res?.name}
                </p>
              ))}
            </div>
          ) : (
            <Spinner height={400} width={"100%"} />
          )}
        </div>
      </section>
    </>
  );
}

export default withTranslation()(PartnorsDetails);
