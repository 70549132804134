import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  Empty,
  Paginationawards,
  SEO,
  Spinner,
  TitleSection,
} from "../../components";
import url from "../../url.json";
import { useSelector } from "react-redux";

function Awards({ t }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  useEffect(() => {
    if (!searchParams.get("page"))
      fetch(url.url + "/about/awards", {
        headers: { Language: localStorage.getItem("lan") },
      })
        .then((res) => res.json())
        .then((data) => setData(data));
    else
      fetch(url.url + `/about/awards?page=${searchParams.get("page")}`, {
        headers: { Language: localStorage.getItem("lan") },
      })
        .then((res) => res.json())
        .then((data) => setData(data));
  }, [selectlan, searchParams.get("page")]);
  return (
    <>
      <SEO title={`Center | ${t("awards_section_title")}`} />

      <TitleSection
        img="images/about__bc.jpg"
        title={t("awards_section_title")}
      />

      <section className="award__section">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("about")}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("awards_section_title")}
            </li>
          </ol>
          {data ? (
            data?.data.length > 0 ? (
              <div className="award__items">
                {data?.data.map((res, i) => (
                  <div key={i} className="item">
                    <img src={res.image} alt="" />
                    <div className="text">
                      <h5>{res.title}</h5>
                      <div
                        dangerouslySetInnerHTML={{ __html: res.description }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Empty height={400} />
            )
          ) : (
            <Spinner height={400} />
          )}
          {data?.data.length > 0 ? <Paginationawards data={data} /> : ""}
        </div>
      </section>
    </>
  );
}
export default withTranslation()(Awards);
