import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Empty, SEO, Spinner, TitleSection } from "../../components";
import { useSelector } from "react-redux";
import url from "../../url.json";
function Projects({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = useState();
  const [id, setId] = useState();
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  useEffect(() => {
    fetch(url.url + `/projects/categories`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategory(data);
        if (!searchParams.get("url")) {
          navigate({ pathname: "/projects", search: `?url=${data[0]?.url}` });
          fetch(url.url + `/projects/list/${data[0]?.url}`, {
            headers: { Language: localStorage.getItem("lan") },
          })
            .then((res) => res.json())
            .then((data) => {
              setData(data);
              setload(false);
            });
        } else
          fetch(url.url + `/projects/list/${searchParams.get("url")}`, {
            headers: { Language: localStorage.getItem("lan") },
          })
            .then((res) => res.json())
            .then((data) => {
              setData(data);
              setload(false);
            });
      });
  }, [selectlan, searchParams.get("url")]);
  return (
    <>
      <SEO title={`Center | ${t("projects")}`} />

      <TitleSection img="images/about__bc.jpg" title={t("projects")} />
      <section className="projects__page__context">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("projects")}
            </li>
          </ol>
          <div className="projects_tab">
            <ul className="tabs">
              {category?.map((res, i) => (
                <li key={i}>
                  <Link
                    to={`/projects?url=${res.url}`}
                    className={
                      searchParams.get("url") == res.url ? "active" : ""
                    }
                    onClick={() => {
                      setId(res.id);
                      setload(true);
                    }}
                  >
                    {res.name}{" "}
                    {res.count_projects ? <b>{res.count_projects}</b> : ""}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="panelContainer">
              <div id="panel1" className="panel">
                {data && !load ? (
                  <div className="row">
                    {data.length > 0 ? (
                      data?.map((res, i) => (
                        <div key={i} className="col-lg-6 mb-4">
                          <div className="card card-news with__project">
                            <Link
                              to={`/project_detail?slug=${res.slug}`}
                              className="card-img-top"
                            >
                              <img src={res.bank_logo} alt="" />
                            </Link>
                            <div className="card-body">
                              <h5 className="card-title">{res.name}</h5>
                              <div className="card-text-project">
                                <p>
                                  <span>
                                    {t("Basis_for_project_implementation")}
                                  </span>{" "}
                                  -{res.basis}
                                </p>
                                <p>
                                  <span>{t("region")}</span> - {res.region}
                                </p>
                                <p>
                                  <span>{t("Total_cost")}</span> -{" "}
                                  {res.total_cost}
                                </p>
                                <p>
                                  <span>
                                    {t("Project_implementation_period")}
                                  </span>{" "}
                                  -{res.project_period}{" "}
                                </p>
                                <p>
                                  <span>{t("mfi")}</span> - {res.bank}
                                </p>
                              </div>
                              <div className="text-center">
                                <Link
                                  to={`/project_detail?slug=${res.slug}`}
                                  className="btn-exact"
                                >
                                  {t("details")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <Empty height={400} width={"100%"} />
                    )}
                  </div>
                ) : (
                  <Spinner height={400} />
                )}
              </div>

              <div id="panel2" className="panel"></div>
              <div id="panel3" className="panel"></div>
              <div id="panel4" className="panel"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default withTranslation()(Projects);
