import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { langReducer } from "../Redux/Reducers/Lang/langReducer.js";
import url from "../url.json";
function SelectLan() {
  const selectlan = useSelector((state) => state.langReducer);
  const { t, i18n } = useTranslation();
  const [Icons, setIcons] = useState();
  const [Title, setTitle] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    fetch(url.url + "/language-list", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan]);
  useEffect(() => {
    if (selectlan.value == "uz") {
      setIcons("images/uzbekistan.svg");
      setTitle("O`zbek");
      i18n.changeLanguage("uz");
      dispatch(langReducer("uz"));
    }
    if (selectlan.value == "oz") {
      setIcons("images/uzbekistan.svg");
      setTitle("Ўзбек");
      i18n.changeLanguage("oz");
      dispatch(langReducer("oz"));
    }
    if (selectlan.value == "ru") {
      setIcons("images/russia.svg");
      setTitle("Русский");
      i18n.changeLanguage("ru");
      dispatch(langReducer("ru"));
    }
    if (selectlan.value == "en") {
      setIcons("images/united-states.svg");
      setTitle("English");
      i18n.changeLanguage("en");
      dispatch(langReducer("en"));
    }
  }, [selectlan.value]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (selectlan.value == "uz") {
      setIcons("images/uzbekistan.svg");
      setTitle("O`zbek");
    }
    if (selectlan.value == "oz") {
      setIcons("images/uzbekistan.svg");
      setTitle("Ўзбек");
    }
    if (selectlan.value == "ru") {
      setIcons("images/russia.svg");
      setTitle("Русский");
    }
    if (selectlan.value == "en") {
      setIcons("images/united-states.svg");
      setTitle("English");
    }
  }, [anchorEl]);

  const handleCloses = (lng) => {
    localStorage.setItem("lan", lng);
    dispatch(langReducer(lng));
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  };
  const handleClose = (lng) => {
    setAnchorEl(null);
  };
  return (
    <div className="language drops">
      <span>
        <img width={25} height={25} src={Icons} alt="" /> {Title}
      </span>
      <div className="body_dropdown" style={{ width: 120, fontSize: 12 }}>
        {data?.map((res, i) => (
          <button key={i} onClick={() => handleCloses(res.url)}>
            <img width={25} height={25} src={res.image} alt="" />
            <div> {res.name}</div>
          </button>
        ))}
      </div>
    </div>
  );
}
export default withTranslation()(SelectLan);
