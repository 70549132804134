import { createSlice } from "@reduxjs/toolkit";
import url from "../../../url.json";

const API_URL = url.url;

export const aboutSlide = createSlice({
  name: "about",
  initialState: {
    data: [],
  },
  reducers: {
    addabout: (state, action) => {
      state.data.push(action.payload);
    },
    getabout: (state, action) => {
      state.data = [action.payload];
    },
  },
});

export const getaboutAsync = (data) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}/${data}`, {
      headers: { Language: localStorage.getItem("lan") },
    });
    const datas = await response.json();
    console.log(datas);
    dispatch(getabout(datas));
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const addaboutAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const response = await fetch.post(API_URL, data);
    // console.log(response);
    dispatch(addabout(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { addabout, getabout } = aboutSlide.actions;
export const showabout = (state) => state.aboute;
export default aboutSlide.reducer;
