import React from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";

function Empty(props) {
  const { t } = useTranslation();
  return (
    <div
      className="no-data"
      style={{ height: props.height, width: props.width }}
    >
      <img src="images/empty-folder.gif" height={props.height * 0.33} />
      <p>{t("no_data")}</p>
    </div>
  );
}
export default withTranslation()(Empty);
