import { createSlice } from "@reduxjs/toolkit";
export const openmenuSlice = createSlice({
  name: "openmenu",
  initialState: {
    value: false,
  },
  reducers: {
    openmenuReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { openmenuReducer } = openmenuSlice.actions;
export default openmenuSlice.reducer;
