import React, { useEffect, useRef, useState } from "react";
import {  Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { withTranslation } from "react-i18next";
import SvgMap from "./SvgMap";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
function SectionMap({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [activeIndex, setActiveIndex] = useState(1);
  const navigate = useNavigate();
  const data = useSelector((state) => state.homeProject?.data[0]);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <section
      className="in__map"
      style={{ backgroundImage: "url(images/world_map.png)" }}
    >
      <div className="containers container">
        <h2>{t("Projects_on_the_map")}</h2>
        <div className="row">
          <div className="col-lg-7">
            <SvgMap data={data} activeIndex={activeIndex} />
          </div>
          <div className="col-lg-5">
            <div className="swiper-map-country">
              <Swiper
                slidesPerView={1}
                // onSlideChange={onSlideChange}
                loop
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                effect="slide"
                speed={1000}
                onSlideChange={(swiper) => {
                  setActiveIndex(swiper.realIndex);
                  console.log("swiper=", swiper.realIndex);
                }}
                modules={[Autoplay, Navigation]}
                className="swiper-wrapper"
              >
                {data?.map((res, i) => (
                  <SwiperSlide key={i}>
                    <div className="text-card-style ">
                      <h4
                        style={{ marginBottom: 20, cursor: "pointer" }}
                        onClick={() => {
                          navigate(`project_detail?slug=${res.slug}`);
                        }}
                      >
                        {res.name}
                      </h4>
                      <p style={{ marginTop: 0 }}>
                        <span>{t("Basis_for_project_implementation")}</span> -
                        {res.basis}
                      </p>
                      <p>
                        <span>{t("region")}</span> - {res.region}
                      </p>
                      <p>
                        <span>{t("Total_cost")}</span> - {res.total_cost}
                      </p>
                      <p>
                        <span>{t("Project_implementation_period")}</span> -
                        {res.project_period}{" "}
                      </p>
                      <p>
                        <span>{t("mfi")}</span> - {res.bank}
                      </p>
                      <img
                        src={res.bank_logo}
                        onClick={() => {
                          navigate(`project_detail?slug=${res.slug}`);
                        }}
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                ))}
                <div className="navigation">
                  <div
                    className="swiper__button__prev"
                    style={{ cursor: "pointer" }}
                    ref={navigationPrevRef}
                  >
                    <img src="images/left.svg" alt="" />
                  </div>
                  <div
                    className="swiper__button__next"
                    style={{ cursor: "pointer" }}
                    ref={navigationNextRef}
                  >
                    <img src="images/right.svg" alt="" />
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withTranslation()(SectionMap);
