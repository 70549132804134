import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Metas({t}) {
  const selectlan = useSelector((state) => state.langReducer);
  const about = useSelector((state) => state.about?.data[0]);

  return (
    <div className="application">
      <Helmet>
        <title>{about?.name}</title>
        <link rel="icon" type="image/png" href={about?.logo} sizes="16x16" />

        {/* <link rel="icon" href={about?.logo} /> */}
      </Helmet>
    </div>
  );
}
export default withTranslation()(Metas);
