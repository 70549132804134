import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { Empty, SEO, Spinner, TitleSection } from "../components";
import dateFormat from "dateformat";
import url from "../url.json";
function Search({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetch(url.url + `/search?search=${searchParams.get("search") ?? ""}`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  }, [selectlan]);

  return (
    <>
      <TitleSection
        img="images/earth.jpg"
        title={t("search") + ": " + (searchParams.get("search") ?? "")}
      />
      <SEO title={`Center | ${t("search")}`} />

      {!loading ? (
        <section className="news__page__context">
          <div className="container">
            <div className="row">
              {/* Yangiliklar */}
              <div className="col-lg-12">
                {data?.articles?.length > 0 ? (
                  <>
                    <div
                      className="d-flex top-section"
                      style={{ justifyContent: "center" }}
                    >
                      <ol className="breadcrumb">
                        <h2
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {t("news")}
                        </h2>
                      </ol>
                    </div>
                    <div className="row">
                      {data?.articles.map((res, i) => (
                        <div key={i} className="col-lg-4 mb-3">
                          <div className="card card-news">
                            <Link
                              to={`/news_detail?slug=${res.slug}`}
                              className="card-img-top"
                            >
                              <img src={res.cover_image} alt="" />
                              <div className="time-news">
                                <img src="images/calendar.svg" />
                                {dateFormat(res.created_at, "dd.mm.yyyy")}
                              </div>
                            </Link>
                            <div className="card-body">
                              <h5 className="card-title">{res.title}</h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: res.sub_description,
                                }}
                                className="card-text"
                              ></div>
                              <div className="text-center">
                                <Link
                                  to={`/news_detail?slug=${res.slug}`}
                                  className="btn-exact"
                                >
                                  {t("details")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              {/* Loyihalar */}
              <div className="col-lg-12">
                {data?.projects?.length > 0 ? (
                  <>
                    <div
                      className="d-flex top-section"
                      style={{ justifyContent: "center" }}
                    >
                      <ol className="breadcrumb">
                        <h2
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {t("projects")}
                        </h2>
                      </ol>
                    </div>
                    <div className="row">
                      {data?.projects.map((res, i) => (
                        <div key={i} className="col-lg-4 mb-3">
                          <div className="card card-news with__project">
                            <Link
                              to={`/project_detail?slug=${res.slug}`}
                              className="card-img-top"
                            >
                              <img src={res.bank_logo} alt="" />
                            </Link>
                            <div className="card-body">
                              <h5 className="card-title">{res.name}</h5>
                              <div className="card-text-project">
                                <p>
                                  <span>
                                    {t("Basis_for_project_implementation")}
                                  </span>{" "}
                                  -{res.basis}
                                </p>
                                <p>
                                  <span>{t("region")}</span> - {res.region}
                                </p>
                                <p>
                                  <span>{t("Total_cost")}</span> -{" "}
                                  {res.total_cost}
                                </p>
                                <p>
                                  <span>
                                    {t("Project_implementation_period")}
                                  </span>{" "}
                                  -{res.project_period}{" "}
                                </p>
                                <p>
                                  <span>{t("mfi")}</span> - {res.bank}
                                </p>
                              </div>
                              <div className="text-center">
                                <Link
                                  to={`/project_detail?slug=${res.slug}`}
                                  className="btn-exact"
                                >
                                  {t("details")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              {/* Hujjatlar */}
              <div className="col-lg-12">
                {data?.regulations?.length > 0 ? (
                  <>
                    <div
                      className="d-flex top-section"
                      style={{ justifyContent: "center" }}
                    >
                      <ol className="breadcrumb">
                        <h2
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {t("document")}
                        </h2>
                      </ol>
                    </div>
                    <div className="document__wrapper">
                      {data?.regulations.map((res, i) => (
                        <div key={i} className="col-lg-9 mb-3">
                          <div className="item" key={i}>
                            <div className="file__icon">
                              <img src="images/file.svg" alt="" />
                            </div>
                            <div className="title">
                              <h4>{res.title}</h4>
                            </div>
                            <div className="download-b">
                              <img src="images/download.svg" alt="" />
                              <span>
                                {res.quantity_downloads} {t("Downloaded")}
                              </span>
                            </div>
                            <div className="calendar-b">
                              <img src="images/calendar-doc.svg" alt="" />
                              <span>
                                {dateFormat(res.published_date, "dd.mm.yyyy")}
                              </span>
                            </div>
                            <a href={res.document} className="btn-details">
                              {t("Download")}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              {data?.articles?.length == 0 &&
              data?.projects?.length == 0 &&
              data?.regulations?.length == 0 ? (
                <div className="col-md-12">
                  <Empty height="400" />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      ) : (
        <Spinner height={500} />
      )}
    </>
  );
}
export default withTranslation()(Search);
