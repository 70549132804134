import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { Empty, Modals, SEO, Spinner, TitleSection } from "../../components";
import url from "../../url.json";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
function ForDiscussion({ t }) {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hrefs, setHrefs] = useState();
  const [id, setId] = useState();
  const [selecttedId, setselecttedid] = useState();
  useEffect(() => {
    fetch(url.url + `/documents/discussion-of-categories`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan, hrefs, modal]);
  function lists() {
    let list = [];
    for (let i = 0; i < data?.length; i++) {
      if (data[i].id == searchParams.get("category")) {
        for (let j = 0; j < data[i].regulations.length; j++) {
          list.push(
            <div className="group-comment" key={j}>
              <div className="item">
                <div className="file__icon">
                  <img src="images/file.svg" alt="" />
                </div>
                <div className="title">
                  <h4>{data[i]?.regulations[j].title}</h4>
                  <div className="bottom-doc">
                    <a
                      data-toggle="modal"
                      onClick={() => {
                        setId(data[i]?.regulations[j].id);

                        toggle();
                      }}
                      data-target="#exampleModalCenter"
                    >
                      {t("Add_a_comment")}
                    </a>
                    <a
                      data-toggle="collapse"
                      className="open-comment-btn "
                      onClick={() => {
                        setselecttedid(data[i]?.regulations[j].id);
                        if (selecttedId == data[i]?.regulations[j].id)
                          setselecttedid(0);
                      }}
                    >
                      {t("View_all_comments")}
                    </a>
                  </div>
                </div>
                <div className="download-b">
                  <img src="images/download.svg" alt="" />
                  <span>
                    {data[i]?.regulations[j].quantity_downloads}{" "}
                    {t("Downloaded")}
                  </span>
                </div>
                <div className="calendar-b">
                  <img src="images/calendar-doc.svg" alt="" />
                  <span>
                    {" "}
                    {dateFormat(
                      data[i]?.regulations[j].published_date,
                      "dd.mm.yyyy"
                    )}
                  </span>
                </div>
                <div className="calendar-b">
                  <img src="images/comment.svg" alt="" />{" "}
                  <span>{data[i]?.regulations[j].quantity_comments}</span>
                </div>
                <a
                  href={`https://center-admin.kibera-soft.uz/api/documents/download/${data[i]?.regulations[j].id}`}
                  className="btn-details"
                  onClick={() => setHrefs(data[i]?.regulations[j].id)}
                >
                  {t("Download")}
                </a>
              </div>
              <div
                className={
                  selecttedId == data[i]?.regulations[j].id
                    ? "collapse show"
                    : "collapse"
                }
              >
                {data[i]?.regulations[j].comments.length > 0 ? (
                  <div className="item__body">
                    {data[i]?.regulations[j].comments.map((res, i) => (
                      <div className="comment-main" key={i}>
                        <h5>{res.fio}</h5>
                        <p>{res.message}</p>
                      </div>
                    ))}
                    <div className="text-center close__collapse">
                      <span onClick={() => setselecttedid(0)}>
                        {t("close")}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="item__body">
                    <Empty height={200} />
                  </div>
                )}
              </div>
            </div>
          );
        }
      }
    }
    return list;
  }
  const [fio, setFio] = useState();
  const [contact, setContact] = useState();
  const [message, setMessage] = useState();
  const handesave = (e) => {
    e.preventDefault();
    const data = {
      fio: fio,
      contact: contact,
      message: message,
    };
    fetch(url.url + `/documents/store-comment/${id}`, {
      body: JSON.stringify(data),
      method: "post",
      headers: {
        Language: localStorage.getItem("lan"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message) {
          console.log(res.message);
          toast.success(res.message);
          setModal(false);
        }
      });
  };
  return (
    <>
      <SEO title={`Center | ${t("for_discussion")}`} />

      <TitleSection img="images/document_bc.jpg" title={t("for_discussion")} />
      <section className="document__page__context modal-open">
        <div className="container containers">
          {data[0]?.regulations ? (
            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex top-section">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("home")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("for_discussion")}
                    </li>
                  </ol>
                </div>

                <div className="document__wrapper">
                  {searchParams.get("category")
                    ? lists()
                    : data[0]?.regulations?.map((res, i) => (
                        <div className="group-comment" key={i}>
                          <div className="item">
                            <div className="file__icon">
                              <img src="images/file.svg" alt="" />
                            </div>
                            <div className="title">
                              <h4>{res.title}</h4>
                              <div className="bottom-doc">
                                <a
                                  data-toggle="modal"
                                  onClick={() => {
                                    setId(res.id);

                                    toggle();
                                  }}
                                  data-target="#exampleModalCenter"
                                >
                                  {t("Add_a_comment")}
                                </a>
                                <a
                                  data-toggle="collapse"
                                  className="open-comment-btn "
                                  onClick={() => {
                                    setselecttedid(res.id);
                                    if (selecttedId == res.id)
                                      setselecttedid(0);
                                  }}
                                >
                                  {t("View_all_comments")}
                                </a>
                              </div>
                            </div>
                            <div className="download-b">
                              <img src="images/download.svg" alt="" />
                              <span>
                                {res.quantity_downloads} {t("Downloaded")}
                              </span>
                            </div>
                            <div className="calendar-b">
                              <img src="images/calendar-doc.svg" alt="" />
                              <span>
                                {" "}
                                {dateFormat(res.published_date, "dd.mm.yyyy")}
                              </span>
                            </div>
                            <div className="calendar-b">
                              <img src="images/comment.svg" alt="" />{" "}
                              <span>{res.quantity_comments}</span>
                            </div>
                            <a
                              href={`https://center-admin.kibera-soft.uz/api/documents/download/${res.id}`}
                              className="btn-details"
                              onClick={() => setHrefs(res.id)}
                            >
                              {t("Download")}
                            </a>
                          </div>
                          <div
                            className={
                              selecttedId == res.id
                                ? "collapse show"
                                : "collapse"
                            }
                          >
                            {res.comments.length > 0 ? (
                              <div className="item__body">
                                {res.comments.map((res, i) => (
                                  <div className="comment-main" key={i}>
                                    <h5>{res.fio}</h5>
                                    <p>{res.message}</p>
                                  </div>
                                ))}
                                <div className="text-center close__collapse">
                                  <span onClick={() => setselecttedid(0)}>
                                    {t("close")}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="item__body">
                                <Empty height={200} />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="side__menu" style={{ top: 0 }}>
                  <h5>{t("servis_information")}</h5>
                  <ul>
                    {data?.map((res, i) => (
                      <li key={i}>
                        <Link
                          to={`/for_discussion?category=${res.id}`}
                          className={
                            res.id == searchParams.get("category")
                              ? "active"
                              : ""
                          }
                        >
                          {res.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <Spinner height={400} />
          )}
        </div>
      </section>
      <Modals toggle={toggle} modal={modal} className="modal-dialog">
        <div className="modal-content modal__style">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            >
              <img src="images/close.svg" alt="" />
            </button>
            <div className="">
              <h2>{t("Add_a_comment")}</h2>
              <form className="row row-60 contact-form" onSubmit={handesave}>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      required
                      id="name"
                      onChange={(event) => setFio(event.target.value)}
                    />
                    <label htmlFor="name">{t("fio")} *</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      required
                      id="email"
                      onChange={(event) => setContact(event.target.value)}
                    />
                    <label htmlFor="email">{t("contact")} *</label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      name=""
                      id=""
                      placeholder={t("write_message")}
                      onChange={(event) => setMessage(event.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="text-end">
                    <button type="submit" className="btn-send">
                      {t("send")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modals>
    </>
  );
}
export default withTranslation()(ForDiscussion);
