import {
  YMaps,
  Map,
  Placemark,
  SearchControl,
  TrafficControl,
  TypeSelector,
} from "react-yandex-maps";
import React from "react";

export default function Mapcontact(props) {
  return (
    <YMaps>
      <Map
        defaultState={{
          center: [
            parseFloat(props.points?.point_y),
            parseFloat(props.points?.point_x),
          ],
          zoom: 16,
          controls: ["zoomControl", "fullscreenControl"],
        }}
        width="100%"
        height="100%"
        modules={["control.ZoomControl", "control.FullscreenControl"]}
      >
        <Placemark
          modules={["geoObject.addon.balloon"]}
          defaultGeometry={[
            parseFloat(props.points?.point_y),
            parseFloat(props.points?.point_x),
          ]}
          properties={{
            balloonContentBody:
              "This is balloon loaded by the Yandex.Maps API module system",
          }}
        />
        <SearchControl
          options={{
            float: "left",
          }}
        />
        <TrafficControl
          options={{
            float: "right",
          }}
        />
        <TypeSelector
          options={{
            float: "right",
          }}
        />
      </Map>
    </YMaps>
  );
}
