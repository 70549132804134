import React, { useEffect, useState } from "react";
import { useCountUp } from "react-countup";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import url from "../url.json";
import { useNavigate } from "react-router-dom";
function SectionProject({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const navigate = useNavigate();
  const data = useSelector((state) => state.projects?.data[0]);
  useEffect(() => {
    fetch(url.url + "/home/articles", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => console.log(data));
  }, [selectlan]);

  return (
    <section className="projects">
      <video src="images/gifed.mp4" autoPlay={true} muted></video>
      <div className="container containers">
        <h2>{t("projects")}</h2>
        <div className="projects__main">
          {data?.map((res, i) => (
            <div
              style={{ cursor: "pointer" }}
              className="item"
              key={i}
              onClick={() => navigate(`/projects?url=${res.url}`)}
            >
              <img src={res.icon} alt="" />
              <h4>
                <CountUp
                  duration={4}
                  enableScrollSpy
                  end={res.count_projects}
                  style={{ fontSize: 44 }}
                />
              </h4>
              <p>{res.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default withTranslation()(SectionProject);
