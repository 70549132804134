import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Modals, SEO, Spinner, TitleSection } from "../../components";
import url from "../../url.json";
function Management({ t }) {
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [img, setImg] = useState();
  const [des, setDes] = useState();
  const selectlan = useSelector((state) => state.langReducer);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  useEffect(() => {
    fetch(url.url + "/about/managements", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan]);
  return (
    <>
      <SEO title={`Center | ${t("management")}`} />

      <TitleSection img="images/about__bc.jpg" title={t("management")} />

      <section className=" page_height lead__section">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("about")}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("structure")}
            </li>
          </ol>
          {data ? (
            <div className="lead__items">
              {data?.map((res, i) => (
                <div className="item" key={i}>
                  <div className="img">
                    <img src={res.image} alt="" />
                  </div>
                  <div className="text">
                    <h4>{res.fio}</h4>
                    <p>{res.position}</p>
                    <div className="row robita">
                      <div className="col-lg-4">
                        <a href={`tel:${res.contact}`}>
                          <img src="images/android-call-op.svg" alt="" />{" "}
                          {res.contact}
                        </a>
                      </div>
                      <div className="col-lg-2">
                        <a href={`mailto: ${res.email}`}>
                          <img src="images/android-drafts-op.svg" alt="" />
                          {res.email}
                        </a>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn-details"
                      onClick={() => {
                        toggle();
                        setImg(res.image);
                        setDes(res.biography);
                        setName(res.fio);
                      }}
                    >
                      {t("biography")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Spinner height={400} />
          )}
        </div>
        <Modals toggle={toggle} modal={modal} className="modal-dialog ">
          <div className="modal-content modal__style">
            <div className="modal-body ">
              <button type="button" className="close" onClick={toggle}>
                <img src="images/close.svg" alt="" />
              </button>
              <div className="main-modal">
                <img src={img} alt="" />
                <div className="text">
                  <h3>{t("biography")}</h3>
                  <h4>{name}</h4>
                  <div dangerouslySetInnerHTML={{ __html: des }}></div>
                </div>
              </div>
              <button type="button" className="btn-details" onClick={toggle}>
                {t("close")}
              </button>
            </div>
          </div>
        </Modals>
      </section>
    </>
  );
}
export default withTranslation()(Management);
