import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import url from "../../url.json";
import { Pagination, FreeMode, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import {
  Card,
  PaginationTen,
  SectionMap,
  SectionProject,
  SEO,
  Spinner,
  Swipers,
  Tender,
  Useful,
} from "../../components";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import texttags from "../../texttags";

function Home({ t }) {
  const [fio, setFio] = useState();
  const [contact, setContact] = useState();
  const [message, setMessage] = useState();
  const selectlan = useSelector((state) => state.langReducer);

  const handesave = (e) => {
    e.preventDefault();
    const data = {
      fio: fio,
      contact: contact,
      message: message,
    };
    fetch(url.url + "/contacts/store-feedback", {
      body: JSON.stringify(data),
      method: "post",
      headers: {
        Language: localStorage.getItem("lan"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(data.message);
      });
  };
  const Contact = useCallback(() => {
    return (
      <section className="contact_us">
        <div className="container containers">
          <h2>{t("contact_as")}</h2>
          <form className="row row-60 contact-form" onSubmit={handesave}>
            <div className="col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  required
                  id="name"
                  onChange={(event) => setFio(event.target.value)}
                />
                <label htmlFor="name">{t("fio")} *</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  required
                  id="email"
                  onChange={(event) => setContact(event.target.value)}
                />
                <label htmlFor="email">{t("contact")} *</label>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <textarea
                  name=""
                  id=""
                  placeholder={t("write_message")}
                  onChange={(event) => setMessage(event.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="text-end">
                <button type="submit" className="btn-send">
                  {t("send")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }, [selectlan]);
  return (
    <>
      <SEO title="Suv xo'jaligida xorijiy investitsiya loyihalarini amalga oshirish markazi" />

      <section
        className="swiper-container swiper-general "
        style={{ padding: 0 }}
      >
        <Banner />
      </section>
      <SectionProject />
      <SectionMap />
      <Tender />
      <Swipers />
      <Contact />
      <Useful />
    </>
  );
}

export default withTranslation()(Home);
