import React from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";

function NotFound(props) {
  const { t } = useTranslation();
  return (
    <div
      className="no-data"
      style={{ height: props.height, width: props.width }}
    >
      <img src="images/404.gif" height={props.height * 0.6} />
    </div>
  );
}
export default withTranslation()(NotFound);
