import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import langReducer from "./Reducers/Lang/langReducer";

import aboutSlide from "./Reducers/About/about";
import openmenuReducer from "./Reducers/openmenu";
import blogSlide from "./Reducers/Blog";
import projectsSlide from "./Reducers/Projects/projects";
import activeMenuReducer from "./Reducers/activeMenu";
import homeProjectSlide from "./Reducers/Projects/homeProject";
export const store = configureStore({
  reducer: {
    about: aboutSlide,
    langReducer: langReducer,
    openmenu: openmenuReducer,
    blog: blogSlide,
    projects: projectsSlide,
    activeMenu: activeMenuReducer,
    homeProject: homeProjectSlide,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
