import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { Empty, SEO, Spinner, TitleSection } from "../../components";
import url from "../../url.json";
import dateFormat from "dateformat";
function Documents({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [bool, setBool] = useState(false);
  const [hrefs, setHrefs] = useState();
  const [closebool, setClosebool] = useState();
  const spliceString = (text) => {
    if (text.length > 120) {
      const x = text.slice(0, 120).split(" ");
      x.pop();
      let title = x.join(" ") + "...   ";
      return title;
    } else return text;
  };
  useEffect(() => {
    fetch(url.url + `/documents/public-of-categories`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan, hrefs]);
  function lists() {
    let list = [];
    for (let i = 0; i < data?.length; i++) {
      if (data[i].id == searchParams.get("category")) {
        if (data[i].regulations.length > 0)
          for (let j = 0; j < data[i].regulations.length; j++) {
            list.push(
              <div className="item" key={j}>
                <div className="file__icon">
                  <img src="images/file.svg" alt="" />
                </div>
                <div className="title">
                  <h4 style={{ display: "block" }}>
                    {!bool
                      ? spliceString(data[i]?.regulations[j].title)
                      : data[i]?.regulations[j].title + " "}
                    {spliceString(data[i]?.regulations[j].title) !=
                    data[i]?.regulations[j].title ? (
                      <a href="#" onClick={() => setBool(!bool)}>
                        {!bool ? t("more") : t("close")}
                      </a>
                    ) : (
                      ""
                    )}
                  </h4>
                </div>
                <div className="download-b">
                  <img src="images/download.svg" alt="" />
                  <span>
                    {data[i]?.regulations[j].quantity_downloads}{" "}
                    {t("Downloaded")}
                  </span>
                </div>
                <div className="calendar-b">
                  <img src="images/calendar-doc.svg" alt="" />
                  <span>
                    {dateFormat(
                      data[i]?.regulations[j].published_date,
                      "dd.mm.yyyy"
                    )}
                  </span>
                </div>
                <a
                  href={data[i]?.regulations[j].document}
                  className="btn-details"
                  target="_blank"
                  onClick={() => setHrefs(data[i]?.regulations[j].id)}
                >
                  {t("Download")}
                </a>
              </div>
            );
          }
        else {
          list.push(<Empty height={400} />);
        }
      }
    }
    return list;
  }
  return (
    <>
      <TitleSection img="images/document_bc.jpg" title={t("document")} />
      <SEO title={`Center | ${t("document")}`} />

      <section className="document__page__context">
        <div className="container containers">
          {data ? (
            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex top-section">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("home")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("document")}
                    </li>
                  </ol>
                </div>

                <div className="document__wrapper">
                  {searchParams.get("category")
                    ? lists()
                    : data[0]?.regulations?.map((res, i) => (
                        <div className="item" key={i}>
                          <div className="file__icon">
                            <img src="images/file.svg" alt="" />
                          </div>
                          <div className="title">
                            <h4 style={{ display: "block" }}>
                              {!bool
                                ? spliceString(res.title)
                                : res.title + " "}
                              {spliceString(res.title) != res.title ? (
                                <a href="#" onClick={() => setBool(!bool)}>
                                  {!bool ? t("more") : t("close")}
                                </a>
                              ) : (
                                ""
                              )}
                            </h4>
                          </div>
                          <div className="download-b">
                            <img src="images/download.svg" alt="" />
                            <span>
                              {res.quantity_downloads} {t("Downloaded")}
                            </span>
                          </div>
                          <div className="calendar-b">
                            <img src="images/calendar-doc.svg" alt="" />
                            <span>
                              {dateFormat(res.published_date, "dd.mm.yyyy")}
                            </span>
                          </div>
                          <a
                            href={`https://center-admin.kibera-soft.uz/api/documents/download/${res.id}`}
                            onClick={() => setHrefs(res.id)}
                            className="btn-details"
                            target="_blank"
                          >
                            {t("Download")}
                          </a>
                        </div>
                      ))}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="side__menu">
                  <h5>{t("servis_information")}</h5>
                  <ul>
                    {data?.map((res, i) => (
                      <li key={i}>
                        <Link
                          to={`/document?category=${res.id}`}
                          className={
                            res.id == searchParams.get("category")
                              ? "active"
                              : ""
                          }
                        >
                          {res.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <Spinner height={400} />
          )}
        </div>
      </section>
    </>
  );
}
export default withTranslation()(Documents);
