import React from "react";

export default function MarkerSvg({ y = 125, x = 460, animation = "" }) {
  return (
    <svg y={y} x={x} id="marker1">

<g className="ldl-scale"
        style={{
          transformOrigin: "50% 50% 0px",
          transform: "rotate(0deg) scale(0.7)",
          // transform: "rotate(0deg) scale(0.8)",
          animationPlayState: "paused",
        }}>
{/* <path d="M15.708 45C15.0005 45 14.3455 44.626 13.986 44.017L11.7935 40.3085C7.2915 32.698 3.04 25.51 1.5875 22.5945C0.531501 20.429 0 18.117 0 15.708C0 7.0465 7.0465 0 15.708 0C24.3695 0 31.416 7.0465 31.416 15.708C31.416 18.1155 30.8845 20.427 29.8375 22.5785C29.825 22.6045 29.811 22.6305 29.7975 22.656C28.317 25.6105 24.0925 32.7525 19.621 40.3105L17.43 44.017C17.0705 44.626 16.4155 45 15.708 45Z" fill="#0488DB"

/> */}
{/* <path d="M15.708 45C15.0005 45 14.3455 44.626 13.986 44.017L11.7935 40.3085C7.2915 32.698 3.04 25.51 1.5875 22.5945C0.531501 20.429 0 18.117 0 15.708C0 7.0465 7.0465 0 15.708 0C24.3695 0 31.416 7.0465 31.416 15.708C31.416 18.1155 30.8845 20.427 29.8375 22.5785C29.825 22.6045 29.811 22.6305 29.7975 22.656C28.317 25.6105 24.0925 32.7525 19.621 40.3105L17.43 44.017C17.0705 44.626 16.4155 45 15.708 45Z" fill="#0488DB"
    style={{
            stroke: "#fff",
            // fill: "rgb(244, 126, 96)",
            animationPlayState: "paused",
            animation: ".5s alternate infinite " + animation,
          }}
/> */}

<path d="M29.7 44.5C29.7 44.99 29.4024 45.5046 28.7534 46.016C28.1078 46.5247 27.1551 46.996 25.9511 47.3974C23.5461 48.199 20.2047 48.7 16.5 48.7C12.7953 48.7 9.45387 48.199 7.04893 47.3974C5.84489 46.996 4.89215 46.5247 4.24659 46.016C3.59764 45.5046 3.3 44.99 3.3 44.5C3.3 44.01 3.59764 43.4954 4.24659 42.984C4.89215 42.4753 5.84489 42.004 7.04893 41.6026C9.45387 40.801 12.7953 40.3 16.5 40.3C20.2047 40.3 23.5461 40.801 25.9511 41.6026C27.1551 42.004 28.1078 42.4753 28.7534 42.984C29.4024 43.4954 29.7 44.01 29.7 44.5Z" stroke="#0488DB" strokeWidth="0.6"
    style={{
            stroke: "#fff",
            fill: "#0488DB",
          }}
/>
<path d="M15.708 45C15.0005 45 14.3455 44.626 13.986 44.017L11.7935 40.3085C7.2915 32.698 3.04 25.51 1.5875 22.5945C0.531501 20.429 0 18.117 0 15.708C0 7.0465 7.0465 0 15.708 0C24.3695 0 31.416 7.0465 31.416 15.708C31.416 18.1155 30.8845 20.427 29.8375 22.5785C29.825 22.6045 29.811 22.6305 29.7975 22.656C28.317 25.6105 24.0925 32.7525 19.621 40.3105L17.43 44.017C17.0705 44.626 16.4155 45 15.708 45Z" fill="#0488DB"
    style={{
            stroke: "#fff",
            // fill: "rgb(244, 126, 96)",
            animationPlayState: "paused",
            animation: ".5s alternate infinite " + animation,
          }}
/>


</g>


      {/* <g
        className="ldl-scale"
        style={{
          transformOrigin: "50% 50% 0px",
          transform: "rotate(0deg) scale(0.7)",
          // transform: "rotate(0deg) scale(0.8)",
          animationPlayState: "paused",
        }}
      >
        <path
          strokeMiterlimit="10"
          strokeWidth="3.5"
          stroke="#333"
          strokeLinecap="round"
          fill="#f47e60"
          d="M50 7.5c-13.1 0-23.6 10.6-23.6 23.6 0 .9.1 1.8.2 2.7h0c.1.7.2 1.3.4 2l.7 2.4c.2.8.5 1.6.8 2.3L49 91.9c.3.9 1.6.9 1.9 0l20.7-51.2c.3-.8.6-1.5.8-2.3l.7-2.4c.2-.7.3-1.3.4-2h0c.1-.9.2-1.8.2-2.7C73.6 18.1 63.1 7.5 50 7.5zm0 33.7c-5.6 0-10.1-4.5-10.1-10.1s4.5-10 10.1-10 10.1 4.5 10.1 10.1-4.5 10-10.1 10z"
          style={{
            stroke: "rgb(51, 51, 51)",
            fill: "rgb(244, 126, 96)",
            animationPlayState: "paused",
            animation: ".5s alternate infinite " + animation,
          }}
        ></path>
        <path
          strokeMiterlimit="10"
          strokeWidth="3.5"
          stroke="#000"
          strokeLinejoin="round"
          strokeLinecap="round"
          opacity=".2"
          d="M50 7.5c-1.7 0-3.4.2-5 .5 10.7 2.3 18.6 11.8 18.6 23.1 0 .9-.1 1.8-.2 2.7h0c-.1.7-.2 1.3-.4 2l-.7 2.4c-.2.8-.5 1.6-.8 2.3L45 81.8l4 10c.3.9 1.6.9 1.9 0l20.7-51.2c.3-.8.6-1.5.8-2.3l.7-2.4c.2-.7.3-1.3.4-2h0c.1-.9.2-1.8.2-2.7C73.6 18.1 63.1 7.5 50 7.5z"
          style={{
            animationPlayState: "paused",
            animation: ".5s alternate infinite " + animation,
          }}
        ></path>
        <path
          strokeMiterlimit="10"
          strokeWidth="3.5"
          stroke="#fff"
          strokeLinecap="round"
          fill="none"
          d="M39.1 17.5c1.9-1.6 4.2-2.7 6.7-3.3"
          style={{
            stroke: "rgb(255, 255, 255)",
            animationPlayState: "paused",
            animation: ".5s alternate infinite " + animation,
          }}
        ></path>
        <path
          strokeMiterlimit="10"
          strokeWidth="3.5"
          stroke="#fff"
          strokeLinecap="round"
          fill="none"
          d="M34.6 22.9c.2-.4.5-.9.8-1.3"
          style={{
            stroke: "rgb(255, 255, 255)",
            animationPlayState: "paused",
            animation: ".5s alternate infinite " + animation,
          }}
        ></path>
      </g> */}
    </svg>
  );
}
