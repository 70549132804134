import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Empty, SEO, Spinner, TitleSection } from "../../components";
import url from "../../url.json";

function Partnors({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    fetch(url.url + "/about/partners", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
      });
  }, [selectlan]);
  return (
    <>
      <TitleSection img="images/tender_bc.jpg" title={t("partnors")} />
      <SEO title={`Center | ${t("partnors")}`} />

      <section className=" page_height tender__page__context tender">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="d-flex top-section">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/">{t("home")}</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("partnors")}
                  </li>
                </ol>
              </div>

              {data ? (
                data?.length > 0 ? (
                  <div className="lead__items">
                    {data?.map((res, i) => (
                      <div className="item" key={i}>
                        <div className="img">
                          <img src={res?.image} alt="" />
                        </div>
                        <div className="text">
                          <h4>{res?.name}</h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: res?.description,
                            }}
                            className="card-textt"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "100%",
                              // whiteSpace: "nowrap",
                            }}
                          />

                          {/* <p>
                            {res?.position} ИКТ консалтинг и разработка
                            системных решений
                          </p> */}
                          <div className="row robita">
                            <div className="col-lg-4">
                              <a href={`${res?.website}`} target="_blank">
                                <img
                                  className="img_net"
                                  src="images/net.png"
                                  alt=""
                                />{" "}
                                {t("website")}{" "}
                              </a>
                            </div>
                            {/* <div className="col-lg-2">
                              <a href={`mailto: ${res?.email}`}>
                                <img
                                  src="images/android-drafts-op.svg"
                                  alt=""
                                />
                                {res?.email} info@kibera.uz
                              </a>
                            </div> */}
                          </div>
                          <button
                            type="button"
                            className="btn-details"
                            onClick={() => {
                              navigate(`/partnor_detail?slug=${res?.slug}`);
                            }}
                          >
                            {t("details")}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Empty height={400} width={"100%"} />
                )
              ) : (
                <Spinner height={400} width={"100%"} />
              )}

              {/* {data?.length > 0 ? <PaginationTen data={data} /> : ""} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default withTranslation()(Partnors);
