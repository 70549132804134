import { createSlice } from "@reduxjs/toolkit";
import url from "../../../url.json";

const API_URL = url.url;

export const projectsSlide = createSlice({
  name: "projects",
  initialState: {
    data: [],
  },
  reducers: {
    addprojects: (state, action) => {
      state.data.push(action.payload);
    },
    getprojects: (state, action) => {
      state.data = [action.payload];
    },
  },
});

export const getprojectsAsync = (data) => async (dispatch) => {
  try {
    const response = await fetch(`${API_URL}/${data}`, {
      headers: { Language: localStorage.getItem("lan") },
    });
    const datas = await response.json();
    console.log(datas);
    dispatch(getprojects(datas));
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const addprojectsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const response = await fetch.post(API_URL, data);
    // console.log(response);
    dispatch(addprojects(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { addprojects, getprojects } = projectsSlide.actions;
export const showprojects = (state) => state.projectse;
export default projectsSlide.reducer;
