import React from "react";
import { withTranslation } from "react-i18next";

function TitleSection(props) {
  return (
    <section
      className="title_page "
      style={{ backgroundImage: `url(${props.img})` }}
    >
      <div className="container">
        <h1>{props.title}</h1>
      </div>
    </section>
  );
}
export default withTranslation()(TitleSection);
