import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SEO, Spinner, TitleSection } from "../../components";

function Structure({ t }) {
  const about = useSelector((state) => state.about?.data[0]);

  return (
    <>
      <SEO title={`Center | ${t("structure_section_title")}`} />

      <TitleSection
        img="images/about__bc.jpg"
        title={t("structure_section_title")}
      />

      <section className="about__page__context">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("about")}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("structure")}
            </li>
          </ol>
          {about?.structure ? (
            <img src={about?.structure} className="w-100" alt="" />
          ) : (
            <Spinner height={400} />
          )}
        </div>
      </section>
    </>
  );
}
export default withTranslation()(Structure);
