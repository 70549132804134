import { Pagination, FreeMode, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import dateFormat from "dateformat";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
function WeatherSide({ t }) {
  const [city, setCity] = useState();
  const [daily, setDaily] = useState();
  useEffect(() => {
    fetch(
      `https://www.meteo.uz/index.php/forecast/map?date=${dateFormat(
        new Date(),
        "yyyy-mm-dd"
      )}&day_part=day&expand=city`,
      { headers: { Accept: "application/json" } }
    )
      .then((data) => data.json())
      .then((res) => {
        setCity(Object.keys(res));
        let arr = [];
        for (let key in res) {
          let obj = res[key];
          obj["name"] = key;
          arr.push(obj);
        }
        setDaily(arr);
      });
  }, []);
  return (
    <div className="row" style={{ width: 300, marginRight: 30 }}>
      <div className="col-lg-12" style={{ justifyContent: "flex-end" }}>
        <Swiper
          // direction="vertical"
          width={250}
          effect="slide"
          speed={1000}
          // height={10}
          className="swiper-wrapper"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          modules={[Autoplay]}
          spaceBetween={30}
        >
          {daily?.slice(0, 14).map((res, i) => (
            <SwiperSlide key={i}>
              <div className="weater-wrap">
                <p>{t(res.name)}</p>
                <div className="div-weater">
                  {res.icon == "partly_cloudy" ? (
                    <img src="images/weather-cloude-sun.svg" />
                  ) : res.icon == "rain" ? (
                    <img src="images/rain.svg" />
                  ) : res.icon == "mostly_clear" ? (
                    <img src="images/sun.svg" />
                  ) : res.icon == "fog" ? (
                    <img src="images/cloud.svg" />
                  ) : res.icon == "snow" ? (
                    <img src="images/snow.svg" />
                  ) : (
                    <img src="images/weather-cloude-sun.svg" />
                  )}
                  <p>
                    {Math.floor(res?.air_t_min) > 0
                      ? "+" + Math.floor(res?.air_t_min) + "°"
                      : Math.floor(res?.air_t_min) < 0
                      ? `${Math.floor(res?.air_t_min)}°`
                      : Math.floor(res?.air_t_min) + "°"}
                  </p>
                  <p>...</p>
                  <p>
                    {Math.floor(res?.air_t_max) > 0
                      ? "+" + Math.floor(res?.air_t_max) + "°"
                      : Math.floor(res?.air_t_max) < 0
                      ? `${Math.floor(res?.air_t_max)}°`
                      : Math.floor(res?.air_t_max) + "°"}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
    // <div className="weather-side">
    //   <div className="date-container">
    //     {/* <h2 className="date-dayname">{WEEKDAYS[weekdayIndex]}</h2> */}
    //     <span className="date-day">
    //       {dayjs.unix(current.dt).format("DD MMM YYYY")}
    //     </span>
    //     <span>
    //       <HiOutlineLocationMarker color="white" className="location-icon" />
    //     </span>
    //     <span className="location">{city.name}</span>
    //   </div>
    //   <div className="weather-container">
    //     <img
    //       className="weather-icon"
    //       src={`http://openweathermap.org/img/wn/${current.weather.icon}@2x.png`}
    //     />
    //     <h1 className="weather-temp">{Math.round(current.temp.max)}°C</h1>
    //     <h3 className="weather-desc">{current.weather.main}</h3>
    //   </div>
    // </div>
  );
}
export default withTranslation()(WeatherSide);
