import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SEO, Spinner, TitleSection } from "../components";
import url from "../url.json";
function Faq({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [id, setId] = useState();
  useEffect(() => {
    fetch(url.url + "/question-answers", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan]);
  return (
    <>
      <TitleSection
        img="images/edifice.jpg"
        title={t("Questions_and_answers")}
      />
      <SEO title={`Center | ${t("Questions_and_answers")}`} />

      <section className=" page_height faq__page__context">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>{" "}
            <li className="breadcrumb-item active" aria-current="page">
              {t("Questions_and_answers")}
            </li>
          </ol>
          {data ? (
            <div id="accordion" className="my__accordion">
              {data?.map((res, i) => (
                <div className="card--item" key={i}>
                  <div className="card__header" id="headingOne">
                    <button
                      className={
                        id !== res.id
                          ? "toggle-accordion"
                          : "toggle-accordion collapsed"
                      }
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      onClick={() => {
                        setId(res.id);
                        if (id == res.id) setId(0);
                      }}
                    >
                      <span className="down">
                        <img src="images/left-icon.svg" alt="" />
                      </span>
                      {res.question}{" "}
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className={res.id == id ? "collapse " : "collapse show"}
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div
                      className="card-body"
                      dangerouslySetInnerHTML={{
                        __html: res.answer,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Spinner height={400} />
          )}
        </div>
      </section>
    </>
  );
}
export default withTranslation()(Faq);
