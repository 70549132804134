import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Mapcontact, SEO, TitleSection } from "../components";
import url from "../url.json";
import { Alert } from "reactstrap";
import { toast } from "react-toastify";

function Contact({ t }) {
  const [show, setshow] = useState(false);
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [fio, setFio] = useState();
  const [contact, setContact] = useState();
  const [message, setMessage] = useState();
  const onDismiss = () => {
    setshow(false);
  };
  useEffect(() => {
    fetch(url.url + "/contacts/data", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan]);
  const handesave = (e) => {
    e.preventDefault();
    const data = {
      fio: fio,
      contact: contact,
      message: message,
    };
    fetch(url.url + "/contacts/store-feedback", {
      body: JSON.stringify(data),
      method: "post",
      headers: {
        Language: localStorage.getItem("lan"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(data.message);
      });
  };

  return (
    <>
      <SEO title={`Center | ${t("contact")}`} />

      <Alert color="info" isOpen={show} toggle={onDismiss}>
        {t("Success!")}{" "}
      </Alert>
      <TitleSection img="images/contacts.jpg" title={t("contact")} />

      <section className="contact__page__context">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">{t("home")}</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("contact")}
            </li>
          </ol>
          <div className="row">
            <div className="col-lg-8">
              <h2>{t("contact_as")}</h2>
              <form className="row row-60 contact-form" onSubmit={handesave}>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      required
                      id="name"
                      onChange={(event) => setFio(event.target.value)}
                    />
                    <label htmlFor="name">{t("fio")} *</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      required
                      id="email"
                      onChange={(event) => setContact(event.target.value)}
                    />
                    <label htmlFor="email">{t("contact")} *</label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      name=""
                      id=""
                      placeholder={t("write_message")}
                      onChange={(event) => setMessage(event.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="text-end">
                    <button type="submit" className="btn-send">
                      {t("send")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <h2>{t("contact")}</h2>
              <ul>
                {data?.contacts.map((res, i) => (
                  <li key={i}>
                    <a href={res.value} target="_blank">
                      <img src={res.icon} alt="" />
                      {res.type == "social" ? res.name : res.value}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="map__contact">
        {data?.points ? <Mapcontact points={data?.points} /> : ""}
      </div>
    </>
  );
}
export default withTranslation()(Contact);
