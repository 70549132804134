import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Mapcontact,
  NotFound,
  SEO,
  Spinner,
  TitleSection,
} from "../../components";
import { FaArrowRight } from "react-icons/fa";
import ReactPlayer from 'react-player/youtube';

import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import url from "../../url.json";
import { Pagination, FreeMode, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../News/styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
function ProjectDetail({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = useState();
  const [id, setId] = useState();
  const navigate = useNavigate();
  const [maps, setmaps] = useState();
  const [urlVideo, setUrlVideo] = useState([]);
  useEffect(() => {
    fetch(url.url + `/projects/show/${searchParams.get("slug")}`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setUrlVideo(data?.link?.url);
        setmaps({ point_x: data?.point_x, point_y: data?.point_y });
      });
    // setUrlVideo(['https://www.youtube.com/watch?v=qxRGA_vcG7Y', 'https://www.youtube.com/watch?v=4gHFcEXAys4', 'https://www.youtube.com/watch?v=FDEcIGWQoFs']);
  }, [selectlan, searchParams.get("url")]);
  return data ? (
    !data.message ? (
      <>
        <SEO title={data?.name} name={data?.name} />
        <section
          className="title_page detail__w"
          style={{ backgroundImage: `url(${data?.cover_image})` }}
        >
          <div className="container">
            <h1>
              {data?.name}
              <img src={data?.bank_logo} alt="" />
            </h1>
          </div>
        </section>
        <section className="projects__detail__page__context">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/">{t("home")}</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to="/projects">{t("projects")}</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {data?.name}
              </li>
            </ol>

            <div className="det">
              <h3>{t("Project_initiator_and_name")}</h3>
              <p>{data?.project_name}</p>
              <h3>{t("mfi")}</h3>

              <a href={data?.bank_article_url ? data?.bank_article_url : "#"}>
                <p>
                  {data?.bank} <FaArrowRight color="#0f4c82" fill="#0f4c82" />
                </p>
              </a>

              <h3>{t("Basis_for_project_implementation")}</h3>
              <p>{data?.basis}</p>
              <h3>{t("project_feasibility_study")}</h3>
              <p>{data?.feasibility_study}</p>
              <div className="block-real row">
                <div className="col-lg-4">
                  <div className="item">
                    <img src="images/cal.svg" alt="" />
                    <p>{t("period")}</p>
                    <span>{data?.project_period}</span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="item">
                    <img src="images/dollar.svg" alt="" />
                    <p>{t("total_cost")}</p>
                    <span>{data?.total_cost}</span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="item">
                    <img src="images/u_map-marker.svg" alt="" />
                    <p>{t("region")}</p>
                    <span>{data?.region}</span>
                  </div>
                </div>
              </div>
              {/* <h3>{t("Date_of_signing_of_the_financial_agreement")}</h3>
              <p>{dateFormat(data?.date_agreement, "dd.mm.yyyy")}</p> */}
              {/* <h3>{t("Terms_and_procedure_for_repayment_of_loans")}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.terms_repayment,
                }}
              ></p> */}
              {data?.project_items?.map((res, i) => (
                <div key={i} className="det">
                  <h3>{res?.label}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: res?.value,
                    }}
                  ></p>
                </div>
              ))}
              {/* <h3>{t("Project_Consultant")}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.project_consultant,
                }}
              ></p> */}
              {/* <h3>{t("Current_status")}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.current_status,
                }}
              ></p> */}
            </div>
          </div>
        </section>
        <div className="map__contact" id="map">
          {maps ? <Mapcontact points={maps} /> : ""}
        </div>{
          urlVideo != null ? (
            <section className="lenta">
              <div className="container">
                <h2>{t("Video")}</h2>
                <div className="lenta__main">
                  <ReactPlayer controls className='react-player' url={urlVideo} opts={{ ui: 10, asel: true }} width="90%" height="450px" style={{ float: 'none', margin: '0 auto' }} />
                </div>
              </div>
            </section>) : ''
        }
        <section className="lenta">
          <div className="container">
            <h2>{t("phototape")}</h2>
            <div className="lenta__main">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                slidesPerGroup={4}
                loop
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2,
                  },
                  240: {
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 4,
                  },
                }}
                modules={[Pagination, Autoplay]}
                className="swiper-container last__news mb-2"
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                effect="slide"
                speed={1000}
              >
                {data?.images.map((res, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={res.image}
                      data-fancybox="group"
                      data-caption="This image has a caption 1"
                    >
                      <img src={res.image} />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      </>
    ) : (
      <NotFound height={800} />
    )
  ) : (
    <Spinner height={800} />
  );
}

export default withTranslation()(ProjectDetail);
