import React, { useEffect, useState } from "react";
import { Pagination, FreeMode, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import url from "../url.json";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

function Useful({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  useEffect(() => {
    fetch(url.url + "/home/useful-links", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan]);
  return (
    <section className="last__news add--new">
      <div className="container">
        <h2>{t("useful_links")}</h2>

        <Swiper
          slidesPerView={3}
          spaceBetween={60}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            240: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
            },
          }}
          modules={[FreeMode, Pagination, Autoplay]}
          className="swiper-container"
          autoplay
        >
          {data?.map((res, i) => (
            <SwiperSlide key={i}>
              <div className="card card-news">
                <a href={res.url} target="_blank" className="card-img-top">
                  <img src={res.icon} alt="" style={{objectFit:"contain"}}/>
                </a>
                <div className="card-body">
                  {/* <h5 className="card-title">{res.name}</h5> */}
                  <p className="card-text" style={{ textAlign: "center" }}>
                    <a style={{color:"inherit"}} href={res.url} target="_blank">{res.name}</a>
                  </p>

                  {/*<div className="text-center">*/}
                  {/*  <a href={res.url} target="_blank" className="btn-exact">*/}
                  {/*    {t("details")}*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
export default withTranslation()(Useful);
