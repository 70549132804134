import { Routes, Route, useLocation } from "react-router-dom";
import {
  Layout,
  Home,
  Projects,
  News,
  NewsDetail,
  Documents,
  ForDiscussion,
  Senter,
  Structure,
  Management,
  Awards,
  Contact,
  Tender,
  Faq,
  ProjectDetail,
  Search,
  Partnors,
  PartnorsDetails,
} from "./pages";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Metas from "./components/Metas";
import { langReducer } from "./Redux/Reducers/Lang/langReducer";
import { HelmetProvider } from "react-helmet-async";
import { activeMenuReducer } from "./Redux/Reducers/activeMenu";
import { NotFound } from "./components";
function App() {
  const dispatch = useDispatch();
  const rout = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (rout.pathname == "/news_detail") {
      dispatch(activeMenuReducer("news"));
    } else {
      if (rout.pathname == "/project_detail") {
        dispatch(activeMenuReducer("projects"));
      } else dispatch(activeMenuReducer(""));
    }
    if (rout.pathname == "/") {
      document.body.classList.add("homepage");
    } else {
      document.body.classList.remove("homepage");
    }
  }, [rout]);
  useEffect(() => {
    if (!localStorage.getItem("lan")) {
      localStorage.setItem("lan", "uz");
      dispatch(langReducer("uz"));
    }
    dispatch(langReducer(localStorage.getItem("lan")));
  }, []);
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <ToastContainer />
        <Metas />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/news" element={<News />} />
            <Route path="/news_detail" element={<NewsDetail />} />
            <Route path="/document" element={<Documents />} />
            <Route path="/for_discussion" element={<ForDiscussion />} />
            <Route path="/center" element={<Senter />} />
            <Route path="/structure" element={<Structure />} />
            <Route path="/management" element={<Management />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/tender" element={<Tender />} />
            <Route path="/partnors" element={<Partnors />} />
            <Route path="/partnor_detail" element={<PartnorsDetails />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/project_detail" element={<ProjectDetail />} />
            <Route path="/search" element={<Search />} />
            <Route path="*" element={<NotFound height={800} />} />
          </Route>
        </Routes>
      </HelmetProvider>
    </>
  );
}

export default App;
