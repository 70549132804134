import React, { useEffect, useRef, useState } from "react";
import {
  Pagination,
  FreeMode,
  Autoplay,
  Navigation,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  EffectFade,
  EffectCards,
  EffectCreative,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import url from "../url.json";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
function Banner({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);
  const dataselect = useSelector((state) => state.blog?.data[0]);
  useEffect(() => {
    setData(dataselect);
  }, [dataselect]);
  return (
    <Swiper
      loop
      slidesPerView={1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      // spaceBetween={30}
      // freeMode={true}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      pagination={{ el: paginationRef.current, clickable: true }}
      modules={[Autoplay, Navigation, Pagination]}
      className="swiper-container"
      effect="slide"
      speed={1000}
      style={{ marginRight: 0 }}
    >
      {data?.map((res, i) => (
        <SwiperSlide key={i} style={{ marginRight: 0 }}>
          <div
            className="swiper-slide"
            style={{ backgroundImage: `url(${res.image})` }}
          >
            <div className="container containers">
              <h2>{res.title}</h2>

              <p
                dangerouslySetInnerHTML={{
                  __html: res.sub_description,
                }}
                className="card-text"
              ></p>
            </div>
            <Link to={`/news_detail?slug=${res.slug}`} className="btn-white">
              {t("details")}
            </Link>
          </div>
        </SwiperSlide>
      ))}

      <div className="swiper-pagination" ref={paginationRef}></div>
      <div className="navigation">
        <div className="swiper__button__prev" ref={navigationPrevRef}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="19" stroke="white" strokeWidth="2" />
            <path
              d="M25.8328 18.8333H16.9742L20.8258 14.9981C21.0455 14.7786 21.169 14.4809 21.169 14.1705C21.169 13.86 21.0455 13.5623 20.8258 13.3428C20.606 13.1233 20.3079 13 19.9971 13C19.6863 13 19.3882 13.1233 19.1684 13.3428L13.3327 19.1714C13.2264 19.2822 13.1431 19.4129 13.0876 19.556C12.9708 19.8398 12.9708 20.1582 13.0876 20.442C13.1431 20.5851 13.2264 20.7158 13.3327 20.8267L19.1684 26.6552C19.2769 26.7644 19.406 26.8512 19.5482 26.9103C19.6905 26.9695 19.843 27 19.9971 27C20.1512 27 20.3037 26.9695 20.446 26.9103C20.5882 26.8512 20.7173 26.7644 20.8258 26.6552C20.9352 26.5468 21.022 26.4179 21.0812 26.2758C21.1405 26.1338 21.171 25.9814 21.171 25.8275C21.171 25.6736 21.1405 25.5213 21.0812 25.3792C21.022 25.2372 20.9352 25.1083 20.8258 24.9999L16.9742 21.1647H25.8328C26.1424 21.1647 26.4393 21.0419 26.6581 20.8233C26.877 20.6047 27 20.3082 27 19.999C27 19.6898 26.877 19.3933 26.6581 19.1747C26.4393 18.9561 26.1424 18.8333 25.8328 18.8333Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="swiper__button__next" ref={navigationNextRef}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              r="19"
              transform="matrix(-1 0 0 1 20 20)"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M14.1672 18.8333H23.0258L19.1742 14.9981C18.9545 14.7786 18.831 14.4809 18.831 14.1705C18.831 13.86 18.9545 13.5623 19.1742 13.3428C19.394 13.1233 19.6921 13 20.0029 13C20.3137 13 20.6118 13.1233 20.8316 13.3428L26.6673 19.1714C26.7736 19.2822 26.8569 19.4129 26.9124 19.556C27.0292 19.8398 27.0292 20.1582 26.9124 20.442C26.8569 20.5851 26.7736 20.7158 26.6673 20.8267L20.8316 26.6552C20.7231 26.7644 20.594 26.8512 20.4518 26.9103C20.3095 26.9695 20.157 27 20.0029 27C19.8488 27 19.6963 26.9695 19.554 26.9103C19.4118 26.8512 19.2827 26.7644 19.1742 26.6552C19.0648 26.5468 18.978 26.4179 18.9188 26.2758C18.8595 26.1338 18.829 25.9814 18.829 25.8275C18.829 25.6736 18.8595 25.5213 18.9188 25.3792C18.978 25.2372 19.0648 25.1083 19.1742 24.9999L23.0258 21.1647H14.1672C13.8576 21.1647 13.5607 21.0419 13.3419 20.8233C13.123 20.6047 13 20.3082 13 19.999C13 19.6898 13.123 19.3933 13.3419 19.1747C13.5607 18.9561 13.8576 18.8333 14.1672 18.8333Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </Swiper>
  );
}
export default withTranslation()(Banner);
