import React, { useEffect, useState } from "react";
import { Pagination, FreeMode, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import url from "../url.json";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
function Swipers({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  useEffect(() => {
    fetch(url.url + "/home/articles", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selectlan]);

  return (
    <section className="last__news">
      <div className="container">
        <h2>{t("Featured_News")}</h2>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          // freeMode={true}
          loop
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            240: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="swiper-container"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect="slide"
          speed={1000}
        >
          {data?.map((res, i) => (
            <SwiperSlide key={i}>
              <div className="card card-news">
                <Link
                  to={`/news_detail?slug=${res.slug}`}
                  className="card-img-top"
                >
                  <img
                    src={res.cover_image}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="time-news">
                    <img src="images/calendar.svg" />
                    {dateFormat(res.published_date, "dd.mm.yyyy")}
                  </div>
                </Link>
                <div className="card-body">
                  <h5 className="card-title">{res.title}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: res.sub_description,
                    }}
                    className="card-text"
                  ></p>

                  <div className="text-center">
                    <Link
                      to={`/news_detail?slug=${res.slug}`}
                      className="btn-exact"
                    >
                      {t("details")}
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
export default withTranslation()(Swipers);
