import { createSlice } from "@reduxjs/toolkit";
export const activeMenuSlice = createSlice({
  name: "activeMenu",
  initialState: {
    value: "",
  },
  reducers: {
    activeMenuReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { activeMenuReducer } = activeMenuSlice.actions;
export default activeMenuSlice.reducer;
