import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function Modals(props) {
  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      className={props.className}
    >
        {props.children}
    </Modal>
  );
}
