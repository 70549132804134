import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { createSearchParams, useNavigate } from "react-router-dom";

export default function Pagination(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bet, setbet] = useState();
  let category = searchParams.get("category");

  function counts() {
    let id = searchParams.get("page");
    let list = [];
    let a = 0;
    if (props.data?.total % props.data?.per_page == 0) {
      a = props.data?.total / props.data?.per_page;
    } else {
      a = parseInt(props.data?.total / props.data?.per_page) + 1;
    }
    for (let i = 1; i <= a; i++) {
      if (i == props.data?.current_page)
        list.push(
          <li className="page-item " key={i}>
            <Link
              className="page-link active"
              to={
                category
                  ? `/news?category=${category}&page=${i}`
                  : `/news?page=${i}`
              }
            >
              {i}
            </Link>
          </li>
        );
      else
        list.push(
          <li className="page-item" key={i}>
            <Link
              className="page-link"
              to={
                category
                  ? `/news?category=${category}&page=${i}`
                  : `/news?page=${i}`
              }
            >
              {i}
            </Link>
          </li>
        );
    }

    return list;
  }

  return (
    <ul className="my__pagination mt-5 mb-3">
      <li className="page-item">
        {!props.data?.links[0].url ? (
          <Link to="#" className="prev disabled">
            <svg
              width="17"
              height="30"
              viewBox="0 0 17 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.68796 14.7467L16.7948 3.2781C17.0738 2.99485 17.0672 2.52714 16.7815 2.2373L14.7953 0.221564C14.5097 -0.06828 14.0447 -0.0748674 13.7657 0.208389L0.200947 14.2131C0.0548037 14.3581 -0.011625 14.5557 0.00166072 14.7467C-0.00498215 14.9443 0.0614465 15.1354 0.200947 15.2803L13.7657 29.2916C14.0447 29.5749 14.5097 29.5683 14.7953 29.2784L16.7815 27.2627C17.0672 26.9729 17.0738 26.5052 16.7948 26.2219L5.68796 14.7467Z"
              />
            </svg>
          </Link>
        ) : (
          <Link
            to={
              category
                ? `/news?category=${category}&page=${
                    props.data?.current_page - 1
                  }`
                : `/news?page=${props.data?.current_page - 1}`
            }
            className="prev "
          >
            <svg
              width="17"
              height="30"
              viewBox="0 0 17 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.68796 14.7467L16.7948 3.2781C17.0738 2.99485 17.0672 2.52714 16.7815 2.2373L14.7953 0.221564C14.5097 -0.06828 14.0447 -0.0748674 13.7657 0.208389L0.200947 14.2131C0.0548037 14.3581 -0.011625 14.5557 0.00166072 14.7467C-0.00498215 14.9443 0.0614465 15.1354 0.200947 15.2803L13.7657 29.2916C14.0447 29.5749 14.5097 29.5683 14.7953 29.2784L16.7815 27.2627C17.0672 26.9729 17.0738 26.5052 16.7948 26.2219L5.68796 14.7467Z"
              />
            </svg>
          </Link>
        )}
      </li>
      {counts()}
      {!props.data?.links[props.data?.links.length - 1].url ? (
        <li className="page-item disabled">
          <Link to="#" className="next disabled">
            <svg
              width="17"
              height="30"
              viewBox="0 0 17 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.312 14.7467L0.205164 3.2781C-0.0738373 2.99485 -0.0671921 2.52714 0.218451 2.2373L2.20467 0.221564C2.49031 -0.06828 2.95531 -0.0748674 3.23431 0.208389L16.7991 14.2131C16.9452 14.3581 17.0116 14.5557 16.9983 14.7467C17.005 14.9443 16.9386 15.1354 16.7991 15.2803L3.23431 29.2916C2.95531 29.5749 2.49031 29.5683 2.20467 29.2784L0.218451 27.2627C-0.0671921 26.9729 -0.0738373 26.5052 0.205164 26.2219L11.312 14.7467Z"
              />
            </svg>
          </Link>
        </li>
      ) : (
        <li className="page-item ">
          <Link
            to={
              category
                ? `/news?category=${category}&page=${
                    props.data?.current_page + 1
                  }`
                : `/news?page=${props.data?.current_page + 1}`
            }
            className="next"
          >
            <svg
              width="17"
              height="30"
              viewBox="0 0 17 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.312 14.7467L0.205164 3.2781C-0.0738373 2.99485 -0.0671921 2.52714 0.218451 2.2373L2.20467 0.221564C2.49031 -0.06828 2.95531 -0.0748674 3.23431 0.208389L16.7991 14.2131C16.9452 14.3581 17.0116 14.5557 16.9983 14.7467C17.005 14.9443 16.9386 15.1354 16.7991 15.2803L3.23431 29.2916C2.95531 29.5749 2.49031 29.5683 2.20467 29.2784L0.218451 27.2627C-0.0671921 26.9729 -0.0738373 26.5052 0.205164 26.2219L11.312 14.7467Z"
              />
            </svg>
          </Link>
        </li>
      )}
    </ul>
  );
}
