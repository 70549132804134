import React, { useState } from "react";
import { Ring, Roller } from "react-awesome-spinners";

export default function Spinner(props) {
  return (
    <div
      className="spinner_item"
      style={{
        width: props.width,
        height: props.height,
        // left: "50%",
        // marginLeft: "50%",
      }}
    >
      <Roller>{props.children}</Roller>
    </div>
  );
}
