import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationUZ from "./locales/uz/translation.json";
import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationUZKR from "./locales/uzkr/translation.json";
const resources = {
  uz: {
    translation: translationUZ,
  },

  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  oz: {
    translation: translationUZKR,
  },
};
i18n
  .use(initReactI18next)

  .init({
    resources,
    lng: localStorage.getItem("lan"),
    fallbackLng: "uz",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
