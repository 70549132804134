import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import url from "../url.json";
function Footer({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [footer, setFooter] = useState();
  const search = (e) => {
    const search = e.target.elements.search.value;
    e.preventDefault();
    window.location.href = "/search?search=" + search;
    // redirect("/search?search=" + search);
  };
  const about = useSelector((state) => state.about?.data[0]);
  useEffect(() => {
    fetch(url.url + "/contacts/data", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setData(data));
    fetch(url.url + "/home/contact-footers", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setFooter(data));
  }, [selectlan]);
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-7">
                <Link to="/" className="logo">
                  <img src={about?.logo} alt="" />
                </Link>
                <p className="logo_subtitle">{about?.name}</p>
                <form
                  onSubmit={search}
                  style={{ marginBottom: 5 }}
                  className="search-form mobile-search-form"
                >
                  <input
                    type="text"
                    name="search"
                    required
                    placeholder={t("search")}
                  />
                  <button type="submit">
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.5248 1.81396C6.37914 1.81396 2.20776 5.98012 2.20776 11.1193C2.20776 16.2585 6.37914 20.4247 11.5248 20.4247C16.6704 20.4247 20.8418 16.2585 20.8418 11.1193C20.8415 5.98025 16.6703 1.81429 11.5248 1.81396Z"
                        stroke="#0F4C82"
                        strokeWidth="3"
                      />
                      <path
                        d="M18.0813 17.6675L24.9828 24.5603"
                        stroke="#0F4C82"
                        strokeWidth="3"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </form>
                <a
                  className="name-ftr-bottom"
                  href="https://kibera.uz"
                  target="_blank"
                >
                  {" "}
                  <span>© Kibera Technology </span>
                </a>
              </div>
              <div className="col-lg-5">
                <ul className="sitemap pl-3">
                  {footer?.tel.map((res, i) => (
                    <li key={i}>
                      <a href={`tel:${res.value}`}>
                        <img src="images/tel.svg" alt="" />
                        {res.value}
                      </a>
                    </li>
                  ))}
                  {footer?.mailto.map((res, i) => (
                    <li key={i}>
                      <a href={`mailto:${res.value}`}>
                        <img src="images/mailto.svg" alt="" />
                        {res.value}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a href="" style={{ pointerEvents: "none" }}>
                      <img src="images/calendar-check.svg" alt="" />
                      Пн - Пт 9:00 - 18:00
                    </a>
                  </li>
                  {footer?.address.map((res, i) => (
                    <li key={i}>
                      <a href="">
                        <img src="images/address.svg" alt="" />
                        {res.value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row">
              <div className="col-lg-4">
                <ul className="list__page">
                  <li>
                    <Link to="/projects">{t("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{t("news")}</Link>
                  </li>
                  <li>
                    <Link to="/document">{t("document")}</Link>
                  </li>
                  <li>
                    <Link to="/center">{t("about")}</Link>
                  </li>
                  <li>
                    <Link to="/contact">{t("contact")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{t("faq")}</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-8">
                <div className="socials">
                  {data?.contacts.map((res, i) =>
                    res.type == "social" ? (
                      <a href={res.value} target="_blank" key={i}>
                        <img src={res.icon} alt="" />
                      </a>
                    ) : (
                      ""
                    )
                  )}
                  {/* <a href="#">
                    <img src="images/telegram.png" alt="" />
                  </a>
                  <a href="#">
                    <img src="images/facebook.png" alt="" />
                  </a>
                  <a href="#">
                    <img src="images/instagram.png" alt="" />
                  </a>
                  <a href="#">
                    <img src="images/twitter.png" alt="" />
                  </a> */}
                </div>
                <form
                  onSubmit={search}
                  className="search-form desctop-search-form"
                >
                  <input
                    type="text"
                    name="search"
                    required
                    placeholder={t("search")}
                  />
                  <button type="submit">
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.5248 1.81396C6.37914 1.81396 2.20776 5.98012 2.20776 11.1193C2.20776 16.2585 6.37914 20.4247 11.5248 20.4247C16.6704 20.4247 20.8418 16.2585 20.8418 11.1193C20.8415 5.98025 16.6703 1.81429 11.5248 1.81396Z"
                        stroke="#0F4C82"
                        strokeWidth="3"
                      />
                      <path
                        d="M18.0813 17.6675L24.9828 24.5603"
                        stroke="#0F4C82"
                        strokeWidth="3"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default withTranslation()(Footer);
