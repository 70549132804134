import React, { useEffect, useState } from "react";
import { Footer, Header, Spinner } from "../components";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getaboutAsync } from "../Redux/Reducers/About/about";
import { getprojectsAsync } from "../Redux/Reducers/Projects/projects.js";
import { gethomeProjectAsync } from "../Redux/Reducers/Projects/homeProject.js";
import { getblogAsync } from "../Redux/Reducers/Blog";
import { Roller } from "react-awesome-spinners";
import { langReducer } from "../Redux/Reducers/Lang/langReducer";
export default function Layout() {
  const dispatch = useDispatch();
  const selectlan = useSelector((state) => state.langReducer);
  const data = useSelector((state) => state.blog?.data[0]);
  const projects = useSelector((state) => state.projects?.data[0]);
  const homeProject = useSelector((state) => state.homeProject?.data[0]);

  useEffect(() => {
    dispatch(getaboutAsync("about/center"));
    dispatch(getblogAsync("home/popular-articles"));
    dispatch(getprojectsAsync("home/indicators"));
    dispatch(gethomeProjectAsync("home/projects"));
  }, [selectlan]);
  useEffect(() => {
    if (!localStorage.getItem("lan")) {
      localStorage.setItem("lan", "uz");
      dispatch(langReducer("uz"));
    }
    dispatch(langReducer(localStorage.getItem("lan")));
  }, []);
  if (selectlan)
    return (
      <>
        {data && projects && homeProject ? (
          <>
            <Header />
            <Outlet />
            <Footer />
          </>
        ) : (
          <div
            className="spinners"
            style={{
              height: 800,
            }}
          >
            <Roller />
          </div>
        )}
      </>
    );
}
