import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import dateFormat from "dateformat";
import url from "../../url.json";
import { Empty, NotFound, SEO, Spinner } from "../../components";
import { activeMenuReducer } from "../../Redux/Reducers/activeMenu";
import { Pagination, FreeMode, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from 'react-player/youtube';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./styles.scss";
function NewsDetail({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [category, setCategory] = useState();
  const [urlVideo, setUrlVideo] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(url.url + `/articles/show/${searchParams.get("slug")}`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setUrlVideo(data?.link?.url);
      });
    fetch(url.url + "/articles/category-list", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategory(data);
      });
    dispatch(activeMenuReducer("news"));
    // setUrlVideo(['https://www.youtube.com/watch?v=qxRGA_vcG7Y', 'https://www.youtube.com/watch?v=4gHFcEXAys4', 'https://www.youtube.com/watch?v=FDEcIGWQoFs']);
  }, [selectlan]);
  return (
    <>
      <SEO
        title={data?.title}
        description={data?.description}
        name={data?.title}
        image={data?.image}
      />
      {data ? (
        !data?.message ? (
          <>
            <section
              className="title_page"
              style={{ backgroundImage: "url('images/earth.jpg')" }}
            >
              <div className="container">
                <h1>{data?.title}</h1>
                <div className="flexible">
                  <div className="time">
                    <img src="images/calendar-w.svg" />{" "}
                    {dateFormat(data?.published_date, "dd.mm.yyyy")}
                  </div>
                  <div className="count-comment">
                    <img src="images/eye-w.svg" alt="" /> {data?.quantity_views}
                  </div>
                </div>
              </div>
            </section>
            <section className="news__page__context">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex top-section">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/">{t("home")}</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/news">{t("news")}</NavLink>
                        </li>

                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {data?.title}
                        </li>
                      </ol>
                    </div>
                    <div className="news__wrapper">
                      <div className="photogallery">
                        <a
                          href={data?.image}
                          data-fancybox="group"
                          data-caption="This image has a caption 1"
                        >
                          <img src={data?.image} />{" "}
                        </a>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.description,
                        }}
                      ></div>
                    </div>
                    {
                      urlVideo != null ? (<div className="container">
                        <div className="lenta__main">
                          <ReactPlayer controls className='react-player' url={urlVideo} opts={{ ui: 10, asel: true }} width="90%" height="450px" style={{ float: 'none', margin: '0 auto' }} />
                        </div>
                      </div>) : ''
                    }

                    <div className="share__block">
                      <span>{t("share")} :</span>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=https://center-min-water.uz/news_detail?slug=${searchParams.get(
                          "slug"
                        )}`}
                      >
                        <img src="images/face.png" alt="" />
                      </a>
                      <a
                        href={`https://telegram.me/share/url?url=https://center-min-water.uz/news_detail?slug=${searchParams.get(
                          "slug"
                        )}`}
                      >
                        <img src="images/tg.png" alt="" />
                      </a>
                    </div>

                  </div>
                  <div className="col-lg-3">
                    <div className="side__menu">
                      <h5>{t("servis_information")}</h5>
                      <ul>
                        {category?.map((res, i) => (
                          <li key={i}>
                            <Link
                              to={`/news?category=${res.id}`}
                              className={
                                res.id == data?.article_category_id
                                  ? "active"
                                  : ""
                              }
                            >
                              {res.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="lenta">
              <div className="container">
                <h2>{t("phototape")}</h2>
                <div className="lenta__main">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    slidesPerGroup={4}
                    loop
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      // when window width is >= 640px
                      640: {
                        slidesPerView: 2,
                      },
                      240: {
                        slidesPerView: 1,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 4,
                      },
                    }}
                    modules={[Pagination, Autoplay]}
                    className="swiper-container last__news mb-2"
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    effect="slide"
                    speed={1000}
                  >
                    {data?.images.map((res, i) => (
                      <SwiperSlide key={i}>
                        <a
                          href={res.image}
                          data-fancybox="group"
                          data-caption="This image has a caption 1"
                        >
                          <img src={res.image} />
                        </a>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </section>
          </>
        ) : (
          <NotFound height={800} />
        )
      ) : (
        <Spinner height={800} />
      )}
    </>
  );
}

export default withTranslation()(NewsDetail);
