import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { openmenuReducer } from "../Redux/Reducers/openmenu";
import WeatherSide from "./WeatherSide/WeatherSide";

export default function Mobilemenu() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.openmenu);
  const handleshow = () => {
    dispatch(openmenuReducer(true));
  };
  const navigate = useNavigate();
  const search = (e) => {
    const search = e.target.elements.search.value;
    // e.preventDefault();
    navigate("/search?search=" + search);
    // window.location.href = "/search?search=" + search;
    // redirect("/search?search=" + search);
  };
  return (
    <div className="body">
      <WeatherSide />
      <form onSubmit={search}>
        <input
          type="text"
          name="search"
          placeholder={t("search")}
          onChange={() => dispatch(openmenuReducer(true))}
        />
        <button type="submit">
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.5248 1.81396C6.37914 1.81396 2.20776 5.98012 2.20776 11.1193C2.20776 16.2585 6.37914 20.4247 11.5248 20.4247C16.6704 20.4247 20.8418 16.2585 20.8418 11.1193C20.8415 5.98025 16.6703 1.81429 11.5248 1.81396Z"
              stroke="#0F4C82"
              strokeWidth="3"
            ></path>
            <path
              d="M18.0813 17.6675L24.9828 24.5603"
              stroke="#0F4C82"
              strokeWidth="3"
              strokeLinecap="round"
            ></path>
          </svg>
        </button>
      </form>
      <ul>
        <li>
          <div className="show drop">
            <a
              className="dropdown-toggle"
              to="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("about_center")}
            </a>
            <div className="drop-menu" aria-labelledby="dropdownMenuLink">
              <NavLink className="dropdown-item" to="/center">
                {t("about_center")}
              </NavLink>
              <NavLink className="dropdown-item" to="/structure">
                {t("structure")}
              </NavLink>
              <NavLink className="dropdown-item" to="/management">
                {t("management")}
              </NavLink>
            </div>
          </div>
        </li>
        <li>
          <NavLink to="/partnors">{t("partnors")}</NavLink>
        </li>
        <li>
          <NavLink to="/projects">{t("projects")}</NavLink>
        </li>
        <li>
          <NavLink to="/news">{t("news")}</NavLink>
        </li>
        <li>
          <div className="show drop">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("document")}
            </a>
            <div className="drop-menu" aria-labelledby="dropdownMenuLink">
              <NavLink className="dropdown-item" to="/document">
                {t("document")}
              </NavLink>
              <NavLink className="dropdown-item" to="/for_discussion">
                {t("for_discussion")}{" "}
              </NavLink>
            </div>
          </div>
        </li>

        <li>
          <NavLink to="/contact">{t("contact")}</NavLink>
        </li>
        <li>
          <NavLink to="/tender">{t("tender")}</NavLink>
        </li>
        <li>
          <NavLink to="/faq">{t("faq")}</NavLink>
        </li>
      </ul>
    </div>
  );
}
