import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import {
  Empty,
  Pagination,
  SEO,
  Spinner,
  TitleSection,
} from "../../components";
import dateFormat from "dateformat";
import url from "../../url.json";
function News({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [category, setCategory] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [load, setLoad] = useState(false);
  const [sortValue, setSortValue] = useState("date");
  useEffect(() => {
    setLoad(true);

    if (!searchParams.get("category") && !searchParams.get("page"))
      fetch(url.url + "/articles/list", {
        headers: { Language: localStorage.getItem("lan") },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoad(false);
        });
    fetch(url.url + "/articles/category-list", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoad(false);
        setCategory(data);
      });
  }, [selectlan]);

  useEffect(() => {
    setLoad(true);
    let urlslug = "";
    if (searchParams.get("category")) {
      urlslug += `&category=${searchParams.get("category")}`;
    }
    if (searchParams.get("page")) {
      urlslug += `&page=${searchParams.get("page")}`;
    }
    fetch(url.url + `/articles/list?sort=${sortValue}${urlslug}`, {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoad(false);
      });
  }, [
    selectlan,
    searchParams.get("page"),
    searchParams.get("category"),
    sortValue,
  ]);

  return (
    <>
      <TitleSection img="images/earth.jpg" title={t("news")} />
      <SEO title={`Center | ${t("news")}`} />
      <section className="news__page__context">
        <div className="container">
          {data && !load ? (
            <div className="row">
              <div className="col-lg-9">
                <>
                  <div className="d-flex top-section">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/">{t("home")}</NavLink>
                      </li>

                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {t("news")}
                      </li>
                    </ol>
                    <div>
                      <span className="sort">{t("sort")}:</span>
                      <select
                        name=""
                        id="sort"
                        defaultValue={sortValue}
                        onChange={(e) => setSortValue(e.target.value)}
                      >
                        <option value="date">{t("sort_date")}</option>
                        <option value="popular">{t("sort_popular")}</option>
                      </select>
                    </div>
                  </div>
                  {data?.data.length > 0 ? (
                    <div className="row">
                      {data?.data.map((res, i) => (
                        <div key={i} className="col-lg-4 mb-3">
                          <div className="card card-news">
                            <Link
                              to={`/news_detail?slug=${res.slug}`}
                              className="card-img-top"
                            >
                              <img
                                className="obejctCover"
                                src={res.cover_image}
                                alt=""
                              />
                              <div className="time-news">
                                <img src="images/calendar.svg" />
                                {dateFormat(res.created_at, "dd.mm.yyyy")}
                              </div>
                            </Link>
                            <div className="card-body">
                              <h5 className="card-title">{res.title}</h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: res.sub_description,
                                }}
                                className="card-text"
                              ></div>
                              <div className="text-center">
                                <Link
                                  to={`/news_detail?slug=${res.slug}`}
                                  className="btn-exact"
                                >
                                  {t("details")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Empty height={400} />
                  )}
                </>
              </div>

              <div className="col-lg-3">
                <div className="side__menu">
                  <h5>{t("servis_information")}</h5>
                  <ul>
                    {category?.map((res, i) => (
                      <li key={i}>
                        <Link
                          to={`/news?category=${res.id}`}
                          className={
                            res.id == searchParams.get("category")
                              ? "active"
                              : ""
                          }
                        >
                          {res.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <Spinner height={400} />
          )}
          {data?.data.length > 0 ? <Pagination data={data} /> : ""}
        </div>
      </section>
    </>
  );
}
export default withTranslation()(News);
