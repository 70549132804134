import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import {
  Empty,
  Modals,
  PaginationTen,
  SEO,
  Spinner,
  TitleSection,
} from "../components";
import dateFormat from "dateformat";
import url from "../url.json";

function Tender({ t }) {
  const selectlan = useSelector((state) => state.langReducer);
  const [data, setData] = useState();
  const [category, setCategory] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState();

  const toggle = () => {
    setModal(!modal);
  };
  const [name, setName] = useState();
  const [date, setdate] = useState();
  const [des, setDes] = useState();
  useEffect(() => {
    if (!searchParams.get("category") && !searchParams.get("page"))
      fetch(url.url + "/tenders/list", {
        headers: { Language: localStorage.getItem("lan") },
      })
        .then((res) => res.json())
        .then((data) => setData(data));
    fetch(url.url + "/tenders/category-list", {
      headers: { Language: localStorage.getItem("lan") },
    })
      .then((res) => res.json())
      .then((data) => setCategory(data));
  }, [selectlan]);
  useEffect(() => {
    if (searchParams.get("category"))
      fetch(
        url.url + `/tenders/list?category=${searchParams.get("category")}`,
        {
          headers: { Language: localStorage.getItem("lan") },
        }
      )
        .then((res) => res.json())
        .then((data) => setData(data));
    if (searchParams.get("page"))
      fetch(url.url + `/tenders/list?page=${searchParams.get("page")}`, {
        headers: { Language: localStorage.getItem("lan") },
      })
        .then((res) => res.json())
        .then((data) => setData(data));
  }, [selectlan, searchParams.get("page"), searchParams.get("category")]);
  return (
    <>
      <TitleSection img="images/tender_bc.jpg" title={t("tender")} />
      <SEO title={`Center | ${t("tender")}`} />

      <section className="tender__page__context tender">
        <div className="container">
          {data && category ? (
            <div className="row">
              <div className="col-xl-9 col-lg-8">
                <div className="d-flex top-section">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("home")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("tender")}
                    </li>
                  </ol>
                </div>

                {data?.data.length > 0 ? (
                  <div className="row">
                    {data?.data.map((res, i) => (
                      <div key={i} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                        <div className="text-card-style may_pet">
                          <h4>{res.title}</h4>
                          <p>
                            <span>{t("Summary")} - </span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: res.description.slice(0, 45) + "...",
                              }}
                              className="card-text"
                            ></span>
                          </p>
                          <p>
                            <span>{t("start_date")}</span> -{" "}
                            {dateFormat(res.start_date, "dd.mm.yyyy")}
                          </p>

                          <p>
                            <span>{t("end_date")}</span> -{" "}
                            {dateFormat(res.end_date, "dd.mm.yyyy")}
                          </p>
                          <button
                            className="btn-details"
                            onClick={() => {
                              toggle();
                              setName(res.title);
                              setFile(res?.file);
                              setDes(res.description);
                              setdate({
                                start_date: res.start_date,
                                end_date: res.end_date,
                              });
                            }}
                          >
                            {t("details")}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Empty height={400} />
                )}

                {data?.data.length > 0 ? <PaginationTen data={data} /> : ""}
              </div>
              <div className="col-lg-3">
                <div className="side__menu">
                  <h5>{t("servis_information")}</h5>
                  <ul>
                    {category?.map((res, i) => (
                      <li key={i}>
                        <Link
                          to={`/tender?category=${res.id}`}
                          className={
                            res.id == searchParams.get("category")
                              ? "active"
                              : ""
                          }
                        >
                          {res.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <Spinner height={400} />
          )}
        </div>
      </section>

      <Modals
        toggle={toggle}
        modal={modal}
        className="modal-dialog modal-dialog-centered"
      >
        <div className="modal-content modal__style">
          <div className="modal-body ">
            <button type="button" className="close" onClick={toggle}>
              <img src="images/close.svg" alt="" />
            </button>
            <div className="tender-modal">
              {" "}
              <h2>{name}</h2>
              <div className="time">
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_429_575)">
                    <path
                      d="M10.7143 1.71404H9.42857V0.321184C9.42857 0.143676 9.28465 -0.000244141 9.10714 -0.000244141H8.03571C7.85821 -0.000244141 7.71429 0.143676 7.71429 0.321184V1.71404H4.28571V0.321184C4.28571 0.143676 4.14179 -0.000244141 3.96429 -0.000244141H2.89286C2.71535 -0.000244141 2.57143 0.143676 2.57143 0.321184V1.71404H1.28571C0.575625 1.71404 0 2.28967 0 2.99976V12.4283C0 13.1384 0.575625 13.714 1.28571 13.714H10.7143C11.4244 13.714 12 13.1384 12 12.4283V2.99976C12 2.28967 11.4244 1.71404 10.7143 1.71404ZM10.5536 12.4283H1.44643C1.4038 12.4283 1.36293 12.4114 1.33279 12.3813C1.30265 12.3511 1.28571 12.3102 1.28571 12.2676V4.28547H10.7143V12.2676C10.7143 12.3102 10.6974 12.3511 10.6672 12.3813C10.6371 12.4114 10.5962 12.4283 10.5536 12.4283ZM9.13797 7.05377L5.32613 10.8351C5.2001 10.9601 4.99658 10.9593 4.87154 10.8333L2.86018 8.80561C2.73517 8.67959 2.73597 8.47607 2.862 8.35103L3.47054 7.74739C3.59657 7.62238 3.80009 7.62318 3.9251 7.74924L5.10646 8.94016L8.0816 5.98888C8.20763 5.86387 8.41114 5.86468 8.53618 5.9907L9.13982 6.59922C9.26483 6.72525 9.264 6.92876 9.13797 7.05377Z"
                      fill="#808080"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_429_575">
                      <rect
                        width="12"
                        height="13.71"
                        fill="white"
                        transform="translate(0 -0.000244141)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {dateFormat(date?.start_date, "dd.mm.yyyy")}-
                {dateFormat(date?.end_date, "dd.mm.yyyy")}
              </div>
              <p dangerouslySetInnerHTML={{ __html: des }}></p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                type="button"
                className="btn-details"
                onClick={() => {
                  window.location.href = file;
                }}
              >
                {t("Download")}
              </button>
              <button type="button" className="btn-details" onClick={toggle}>
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </Modals>
    </>
  );
}
export default withTranslation()(Tender);
